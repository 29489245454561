import React from "react";
import LoginImage from '../../assets/img/60111.webp';
import { login } from '../../services/Auth';
import { Link } from "react-router-dom";
import StatusToast from "../toast/StatusToast";
import { loginSendOTP } from '../../services/Voters';



class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      password: '',
      error: '',
      otpSentStatus: false,
      OTPNumber: null,
      OTPVerificationNumber: null,
      otpSentStatusMessage: '',
      OTPVerificationNumberMessage: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMobileNumber = this.handleMobileNumber.bind(this);
    this.handlePwdKeyUp = this.handlePwdKeyUp.bind(this);
    this.handleSendOTP = this.handleSendOTP.bind(this);
    this.handleOTPVerification = this.handleOTPVerification.bind(this);
  }


  handleSendOTP() {
    this.setState({OTPVerificationStatus: false, OTPVerificationNumber: ''})
    if(this.state.mobileNumber?.length == 10) {
      const mobileDataObj = {'mobile_number': this.state.mobileNumber};
        loginSendOTP(mobileDataObj).then((data) => {
            this.setState({otpSentStatus: data?.success, otpSentStatusMessage: data?.message});
        }).catch((err) => {
            this.setState({error: err});
        })
    } else {
      this.setState({otpSentStatusMessage: 'Please enter your mobile number.'});
    }
  }

  handlePwdKeyUp(event){
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmit();
    }
  }

  handleMobileNumber(event) {
    this.setState({error:''});
    this.setState({mobileNumber: event.target.value, otpSentStatusMessage: ''});
  }

  handleOTPVerification(event) {
    if(event.target.value )
    this.setState({error:''});
    this.setState({OTPVerificationNumber: event.target.value, OTPVerificationNumberMessage: ''});
  }


  handleSubmit() {
    if(this.state.OTPVerificationNumber?.length == 6) {
      const params = {
        "mobile_number": this.state.mobileNumber,
        "otp": this.state.OTPVerificationNumber
      }
      login(params, this.props.onLogout).then((msg) => {
        this.setState({OTPVerificationNumberMessage: ''});
        this.props.onLogin();
      }).catch((err) => {
        this.setState({OTPVerificationNumberMessage: 'Failed to login. Please try again.'});
      })
    } else if(this.state.OTPVerificationNumber?.length < 6) {
      this.setState({OTPVerificationNumberMessage: 'Please enter a valid otp.'});
    } else if(this.state.mobileNumber?.length != 10) {
      this.setState({OTPVerificationNumberMessage: 'Please enter a valid mobile number.'});
    } else {
      this.setState({OTPVerificationNumberMessage: 'Please enter a valid otp.'});
    }
  }

  render() {
    return(
      
      // Set margin to 0 for content wrapper in login page.
      <div className="content-wrapper" style={{margin: 0}}>
        <div className='container-fluid'>
		    <StatusToast status='Voter Survey' delayTime="3000"/>
          <div className="login-container">
            <div className="row jbjd-w-md-50">
              <div className="col-12 m-auto">
                <div className="card p-2 mx-md-5">
                  <div className="card-content">
                    <form action="" >
                      <div className="row">
                        <div className="col-12">
                          <div className="py-3 px-5">
                            <img src={LoginImage} className='rounded mx-auto d-block' alt="" width={100} height={100} />
                          </div>
                        </div>
                        <div className="col-12 col-md-8">
                          <div className="form-group">
                            <input type="tel" className="form-control" placeholder="Mobile Number" name="username" id="username" value={this.state.mobileNumber} onChange={this.handleMobileNumber} maxLength={10} minLength={10} required readOnly={this.state.otpSentStatus ? true : false}/>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                              <button type="button" className="btn btn-success" onClick={this.handleSendOTP}>{this.state.otpSentStatus == true ? 'Resend OTP' : 'Send OTP' }</button>
                            </div>
                          </div>
                          <div className="col-12">
                            <span className="text-danger fw-600">{ this.state.otpSentStatusMessage ? this.state.otpSentStatusMessage : '' }</span>
                          </div>
                        {
                          this.state.otpSentStatus == true ?
                            <>
                            <div className="col-12 col-md-8">
                              <div className="form-group">
                                <input type="tel" name="otpverification" id="otpverification" placeholder="OTP" className="form-control" value={this.state.OTPVerificationNumber} onChange={this.handleOTPVerification} readOnly={this.state.OTPVerificationStatus ? true : false} onKeyUp={this.handlePwdKeyUp} maxLength={6} minLength={6} />
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <button type="button" className="btn btn-success" disabled={this.state.OTPVerificationStatus ? true : false} onClick={this.handleSubmit}>Verify OTP</button>
                              </div>
                            </div>
                            <div className="col-12">
                              <span className="text-danger fw-600">{ this.state.OTPVerificationNumberMessage ? this.state.OTPVerificationNumberMessage : '' }</span>
                            </div>
                            </> : ''
                          
                        }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;