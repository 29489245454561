import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import DashboardMembership from './DashboardMembership';
import { getPrimaryMembershipList } from '../../services/Voters';
import DashboardMembershipNoData from './DashboardMembershipNoData';
import DashboardPrimaryMembershipChart from './DashboardPrimaryMembershipChart';
import { Link } from 'react-router-dom';


function getMediumTypeWiseChart(data) {

	let option = {
		series: [Math.round(data / 24 * 100)],
            options: {
              chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                  enabled: true
                }
              },
              plotOptions: {
                radialBar: {
                  startAngle: -90,
                  endAngle: 90,
                  track: {
                    background: "#e7e7e7",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                      enabled: true,
                      top: 2,
                      left: 0,
                      color: '#999',
                      opacity: 1,
                      blur: 2
                    }
                  },
                  dataLabels: {
                    name: {
                      show: false
                    },
                    value: {
                      offsetY: -2,
                      fontSize: '22px'
                    }
                  }
                }
              },
              grid: {
                padding: {
                  top: -10
                }
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'light',
                  shadeIntensity: 0.4,
                  inverseColors: false,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 50, 53, 91]
                },
              },
              labels: ['Average Results'],
            },
		};

	return option;
}

class Dashboard extends React.Component {
  
  constructor(props) {
	super(props);
	this.state = {
	  value: '',
	  cards: [],
	  importantVotersData: [],
	  filterShowing: true,
	  pollingStationInchargesData: [],
	  houseVisitedCountData: null,
	  commentsOfVolunteerData: [],
	  selectedConstituency: 86,
	  selectedBlock: null,
	  selectedVillage: null,
	  selectedPollingStation: null,
	  constituencies: [],
	  blocks: [],
      villages: [],
      pollingStationList: [],
	  importantVoterError: null,
	  importantVotersLoaderStatus: false,
	  initialPage: '1',
	  selectedConstituencyObj: { "id": 86, "name": "Munugode" },
	  houseVisitedCountLoader: false,
	  houses: null,
	  houseLoaderStatus: false,
	  voterCount: null,
	  voterCountLoader: false,
	  acDisabled: false,
	  blockDisabled: false,
	  pageNumber: 1,
	  pageSize: 10,
	  selfAddedVoterList: [],
	  mediumTypeWiseData: {},
	};

	this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
	this.setState({value: event.target.value, filterShowing: !this.state.filterShowing});
  }

  componentDidMount() {
	getPrimaryMembershipList().then((data) => {
		this.setState({selfAddedVoterList: data});
	}).catch((err) => {
		this.setState({error: err});
	})
  }

  render() {
	let radialBarData = getMediumTypeWiseChart(this.state.selfAddedVoterList?.length);
	return(
		<>
			<div className="row mx-md-5 mx-0">
        <din className="col-12 text-md-end mb-3 py-3 rounded text-center">
          <Link to={ `/editprofile` } className='btn border jb-button-style me-3 header-mobile-margin-bottom'>Edit Profile</Link>
          <Link to={ `/selfmembershipcard` } className='btn border jb-button-style'>My Membership Id</Link>
        </din>
				<div className="col-12 col-md-12 col-lg-8 order-lg-1 order-2">
				{
					this.state.selfAddedVoterList.length != 0 ? <DashboardMembership membersList={this.state.selfAddedVoterList} /> : <DashboardMembershipNoData />
				}
				</div>
				<div className="col-12 col-md-12 col-lg-4 order-1">
					<DashboardPrimaryMembershipChart title={'Primary Membership'} horizontalBarData={radialBarData} />
				</div>
			</div>
		</>
	);
  }
}

export default Dashboard;