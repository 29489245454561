import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Header-Style.css';
import { NavLink,Link } from 'react-router-dom';
import { logout } from '../services/Auth';
import { getUserProfile, getSelfDetails, mediaURL } from '../services/Voters';
import userImage from '../assets/img/usericon.png';
import Popup from 'reactjs-popup';
import { BRANDING_INNER_HEADER_TITLE } from '../config/defaults';
import profileImagePlaceholder from '../assets/img/profile-image-placeholder-01.png';


const Header = (props) => {

  const [user,setUser] = useState();

  const [userInfo,setUserInfo] = useState(null);

const [colleps,setColleps] = useState(true);
const [selfData,setSelfData] = useState(null);
const [selfProfileImage,setSelfProfileImage] = useState(userImage);

const checkUserisLoggedin = () =>{

  getUserProfile().then((UserProfile) =>{
	setUserInfo({UserProfile});
  }).catch((error) => {
	console.log(error);
  });
}

  useEffect(() =>{
	checkUserisLoggedin();

	getSelfDetails().then((data) => {
		if(data != undefined) {
			let profileImage = data?.profile_image;
			let image = null;
			if(profileImage != null) {
				profileImage = profileImage.replaceAll("\"", "&quot;")
				profileImage = profileImage.replaceAll("'","\"");
				let dataobj = JSON.parse(profileImage);
				image = mediaURL + dataobj[0].url;
			} else {
				image = profileImagePlaceholder;
			}
			setSelfProfileImage({image});
		}
		setSelfData({data});
	}).catch((err) => {
		console.log(err);
	});
  },[]);

  const handleLogout = () => {
	logout();
	props.onLogout();
  }



function getWindowDimensions() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const ratio = (width / height) * 100;
  return { width, height, ratio };
}

const { width } = getWindowDimensions();

 const onBtnClick = () =>{
	setColleps(prevColleps => !prevColleps);
	if (width < 768) {
	  props.onCollepaseClick(false);
	} else {
	  props.onCollepaseClick(false);
	}
  }

  return(
	<>
		<nav className="main-header navbar navbar-expand navbar-white navbar-light ms-0">
			<a href="/" className="navbar-brand ps-3 d-flex">
				<img src={ '/bjd-logo.webp' } alt="AdminLTE Logo" className="brand-image img-circle elevation-3" width={`80px`} height={`80px`} />
				<span className="brand-text font-weight-light ps-3 fw-600 text-wrap-auto my-auto">Active Member Dashboard</span>
			</a>
	  		<ul className="navbar-nav d-flex px-3 ms-auto">
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
					{
						props.selfProfileImage != undefined ? <img src={mediaURL + props.selfProfileImage} className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={50} height={50} /> : <img src={ selfProfileImage?.image } className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={50} height={50} />
					}
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{left: 'inherit', right: 0 + 'px'}}>
						<div className="user text-center">
							<div className="" style={{marginTop: 25 + 'px'}}>
								{
									props.selfProfileImage != undefined ? <img src={mediaURL + props.selfProfileImage} className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={50} height={50} /> : <img src={ selfProfileImage?.image } className='rounded mx-auto d-block rounded-circle border border-1 border-dark user-select-all' alt="" width={50} height={50} />
								}
							</div>
						</div>
						<div className="mt-1 text-center">
							<h5 className="p-2">{userInfo?.UserProfile?.user?.name ? `${userInfo?.UserProfile?.user?.name}` : `${userInfo?.UserProfile?.user?.mobile_number}`}
							</h5>
						</div>
						<div className="dropdown-divider"></div>
						<a href="/selfmembershipcard" className="dropdown-item dropdown-footer text-center dropdown-menu-item">My Membership ID</a>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item dropdown-footer text-center dropdown-menu-item" onClick={handleLogout}>Logout</a>
					</div>
				</li>
	  		</ul>
  		</nav>
	</>
);
}

export default Header;