import React from "react";
import { mediaURL, getSelfDetails } from '../services/Voters';
import html2canvas from 'html2canvas';
import downloadIcon from '../assets/img/download.png';
import shareIcon from '../assets/img/share.png';
import JOINBJDQR from '../assets/img/joinbjd-qr.jpeg';
import withParams from "./GetParams";
import profileImagePlaceholder from '../assets/img/profile-image-placeholder-01.png';

class SelfMembershipcard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selfData: null,
            profileImage: null,
        };
        this.handleDownloadImage = this.handleDownloadImage.bind(this);
        this.handleCopyClick = this.handleCopyClick.bind(this);
    }
    
    handleCopyClick = async () => {
        try {
            const element = document.getElementById('print');
            element.querySelector('#member-bottom-icon-group').style.display = 'none';
            element.querySelector('.member-details').style.marginBottom = '75px';
        let canvas = html2canvas(element, {useCORS: true});
        canvas.then( async (c) => {
            let d = c.toDataURL('image/png');
            const blob = await (await fetch(d)).blob();
            const data = {
                title: "Join BJD",
                text: '',
                url: "https://joinbjd.in",
                files: [new File([blob], this.state.selfData?.membership_number + '.png', {type: blob.type})]
            };
            if(navigator.canShare(data)) {
                await navigator.share(data);
            }
        });
        element.querySelector('#member-bottom-icon-group').style.display = 'flex';
        element.querySelector('.member-details').style.marginBottom = '0px';
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.",
                err
            );
            alert("Copy to clipboard failed.");
        }
    };

    handleDownloadImage = () => {
        const element = document.getElementById('print');
        element.querySelector('#member-bottom-icon-group').style.display = 'none';
        element.querySelector('.member-details').style.marginBottom = '75px';
        let canvas = html2canvas(element, {useCORS: true, allowTaint: true});
        let link = null;
        let data = null;
        canvas.then((c) => {
            data = c.toDataURL('image/png');
            link = document.createElement('a');
            link.href = data;
            link.download = this.state.selfData?.membership_number + '.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
        element.querySelector('#member-bottom-icon-group').style.display = 'flex';
        element.querySelector('.member-details').style.marginBottom = '0px';
      };

    componentDidMount() {
        getSelfDetails().then((selfData) => {
            let profileImage = selfData?.profile_image;
            if(profileImage != null) {
                profileImage = profileImage.replaceAll("\"", "&quot;")
                profileImage = profileImage.replaceAll("'","\"");
                let dataobj = JSON.parse(profileImage);
                let image = dataobj[0].url;
                this.setState({profileImage: image});
            }
            this.setState({selfData});
        }).catch((err) => {
            this.setState({error: err});
        });
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="card membership-thank-you-card" id="print">
                                <div className="card-body">
                                    <div className="member-details">
                                        <div className="member-details-card">
                                            <div className="member-image">
                                                <div className="image">
                                                    <img src={ this.state.profileImage ? mediaURL + this.state.profileImage : profileImagePlaceholder } alt="Member Name" width="100px" />
                                                </div>
                                            </div>
                                            <div className="memberdetails mb-4 mb-md-5">
                                                <div className="row">
                                                    <div className="col-8 mt-md-auto mt-4">
                                                        <div className="member-name">
                                                            Name: { this.state.selfData?.first_name }
                                                        </div>
                                                        <div className="member-assembly">
                                                            Assembly: { this.state.selfData?.assembly_constituency?.name }
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-auto mb-0">
                                                        <img src={ JOINBJDQR } alt="" width="80%" className="m-auto" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="membership-number">
                                                <span>ମେମ୍ବରସିପ୍ ନଂ: { this.state.selfData?.membership_number }</span>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mt-md-5" id="member-bottom-icon-group">
                                            <div className="col-6 text-center">
                                                <span className="member-bottom-icon fw-semibold bg-white py-2 rounded-pill mx-auto" onClick={this.handleDownloadImage}>Download</span>
                                            </div>
                                            <div className="col-6 text-center">
                                                <span className="member-bottom-icon fw-semibold bg-white py-2 rounded-pill mx-auto" onClick={this.handleCopyClick}>Share</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withParams(SelfMembershipcard);